import { Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const CtaBanner = ({
	heading,
	subtitle,
	content,
	btnCta1,
	btnCta2,
	bgImage,
	textPosition = "center",
	isGreen = false,
	column = 7,
	useBgImage, // New prop to decide background handling
}) => {
	const image = bgImage ? getImage(bgImage) : null;
	const overlayColor = isGreen
		? "rgba(54, 180, 159, 0.60)"
		: "rgba(0, 0, 0, 0.50)";
	const backgroundStyle = {
		background: `linear-gradient(${overlayColor} 0%, ${overlayColor} 100%)`,
		backgroundBlendMode: "multiply",
		zIndex: 0,
	};

	const contentMarkup = (
		<Container style={{ zIndex: 1 }} className="position-relative">
			<Row
				className={`align-items-lg-center justify-content-lg-${textPosition}`}
			>
				<Col lg={column} className={`mb-4 mb-lg-0 text-${textPosition}`}>
					<h2 className="my-0 text-white">{heading}</h2>
					<div
						className="text-white py-4"
						dangerouslySetInnerHTML={{ __html: content }}
					></div>
					{subtitle && <span className="text-white">{subtitle}</span>}
					<div
						className={`d-flex align-items-center justify-content-${textPosition} mt-4`}
					>
						{btnCta1 && btnCta1.url && (
							<Button
								as={Link}
								href={btnCta1.url}
								size="lg"
								className="px-4 py-3 me-3 montserrat-bold fs-6"
								target={btnCta1.target}
								variant="secondary"
							>
								{btnCta1.title}
							</Button>
						)}
						{btnCta2 && btnCta2.url && (
							<Button
								as={Link}
								href={btnCta2.url}
								size="lg"
								className="px-4 py-3 montserrat-bold fs-6 bg-transparent text-white border-white"
								target={btnCta2.target}
							>
								{btnCta2.title}
							</Button>
						)}
					</div>
				</Col>
			</Row>
		</Container>
	);

	if (useBgImage && image) {
		return (
			<BgImage image={image} className="py-5 position-relative py-md-8 w-100">
				<div
					className="position-absolute start-0 top-0 h-100 w-100"
					style={backgroundStyle}
				></div>
				{contentMarkup}
			</BgImage>
		);
	} else {
		return (
			<section
				className="py-5 py-md-8"
				style={sectionWrapper(bgImage?.sourceUrl, isGreen)}
			>
				<Container>
					<Row
						className={`align-items-lg-center justify-content-lg-${textPosition}`}
					>
						<Col lg={column} className={`mb-4 mb-lg-0 text-${textPosition}`}>
							<h2 className="my-0 text-white">{heading}</h2>
							<div
								className="text-white py-4"
								dangerouslySetInnerHTML={{ __html: content }}
							></div>
							{subtitle && <span className="text-white">{subtitle}</span>}
							<div
								className={`d-flex align-items-center justify-content-${textPosition} mt-4`}
							>
								{btnCta1 && btnCta1.url && (
									<Button
										as={Link}
										href={btnCta1.url}
										size="lg"
										className="px-4 py-3 me-3 montserrat-bold fs-6"
										target={btnCta1.target}
										variant="secondary"
									>
										{btnCta1.title}
									</Button>
								)}

								{btnCta2 && btnCta2.url && (
									<Button
										as={Link}
										href={btnCta2.url}
										size="lg"
										className="px-4 py-3 montserrat-bold fs-6 bg-transparent text-white border-white"
										target={btnCta2.target}
									>
										{btnCta2.title}
									</Button>
								)}
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
};
export const sectionWrapper = (imgUrl, isGreen) => ({
	background: isGreen
		? `linear-gradient(0deg, rgba(54, 180, 159, 0.60) 0%, rgba(54, 180, 159, 0.60) 100%), url(${imgUrl}) lightgray 0 / cover no-repeat`
		: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${imgUrl}) lightgray 50% / cover no-repeat`,
});
export default CtaBanner;
